<template>
  <v-container
    fluid
    tag="section"
  >
    <base-material-tabs
      v-model="mainTab"
      background-color="transparent"
      centered
      icons-and-text
      show-arrows
    >
      <v-tab
        v-for="(tab, i) in tabs"
        :key="`tab-${i}`"
        :to="tab.to"
      >
        {{ tab.text }}
        <v-icon v-text="tab.icon" />
      </v-tab>
    </base-material-tabs>

    <v-progress-linear
      v-if="loading"
      indeterminate
    />
    <v-row v-else>
      <v-col
        cols="12"
        md="8"
      >
        <router-view />
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          image
          hover-reveal
          color="white"
          elevation="8"
          class="company-class-profile"
          width="128"
        >
          <input
            ref="file"
            type="file"
            class="d-none"
            @change="uploadPhoto"
          >

          <div
            class="status-badge"
            style="padding: 5px;"
          >
            <v-speed-dial
              direction="bottom"
            >
              <template v-slot:activator>
                <v-btn
                  fab
                  small
                  :loading="acting"
                  color="accent"
                >
                  <v-icon>
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>
              <v-tooltip
                v-for="(action, j) in actions"
                :key="j"
                left
              >
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    v-bind="attrs"
                    :color="action.color"
                    fab
                    small
                    class="ma-0"
                    @click="trigger(action.action)"
                    v-on="on"
                  >
                    <v-icon v-text="action.icon" />
                  </v-btn>
                </template>

                <span>{{ action.what }}</span>
              </v-tooltip>
            </v-speed-dial>
          </div>

          <template v-slot:image>
            <v-icon
              size="100"
              color="grey"
            >
              mdi-umbrella
            </v-icon>
          </template>

          <template
            v-slot:reveal-actions
          >
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  text
                  :loading="uploading"
                  v-on="on"
                  @click="$refs.file.click()"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Upload/Change Image</span>
            </v-tooltip>
          </template>

          <v-card-text class="text-h3 text-center">
            {{ companyClass.name }}
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { Api } from '@/apis'

  export default {
    data: () => ({
      mainTab: 0,
      tabs: [
        { text: 'General', icon: 'mdi-information', to: 'general' },
        { text: 'Address', icon: 'mdi-map-marker', to: 'address' },
        { text: 'Contacts', icon: 'mdi-card-account-phone', to: 'contacts' },
        { text: 'Files', icon: 'mdi-file', to: 'files' },
        { text: 'Notes', icon: 'mdi-pen', to: 'notes' },
      ],
      loading: false,
      uploading: false,
      acting: false,
      actions: [
        { icon: 'mdi-delete', color: 'error', what: 'Delete', action: 'delete' },
      ],
    }),

    computed: {
      ...mapState({
        companyClass: state => state.items.companyClass,
      }),
    },

    mounted () {
      this.getCompanyClass()
    },

    methods: {
      ...mapActions({
        getItem: 'getItem',
      }),

      async getCompanyClass () {
        this.loading = true
        const item = { url: `company/company/${this.$route.params.id}?agency=1`, item: 'companyClass' }
        await this.getItem(item)
        this.loading = false
      },

      uploadPhoto () {},

      async trigger (action) {
        switch (action) {
          case 'delete': {
            const confirm = await this.$confirm('Are you sure you want to delete?', { title: 'Warning' })
            if (confirm) {
              const response = await new Api().delete('agencies/agency/' + this.$route.params.id)
              if (response) {
                this.$router.push('/')
              }
            }
            break
          }
          default:
            break
        }
      },
    },
  }
</script>

<style lang="sass">
.company-class-profile
  .v-card--material__heading
    display: flex
    justify-content: center
    align-items: center
    border-radius: 50%
    width: 128px
    min-width: 128px
    height: 128px
</style>
